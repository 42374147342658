import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { validateZip } from '../../utilities/validate-zip';

const LocationInput = ({ location, updateLocation }) => {
	const { widgetName, windowId, deviceType } = useRequestData();
	const labels = useLabels();
	const [showForm, setShowForm] = useState(false);
	const [input, setInput] = useState(location[0]);
	const [isValidZip, setValidZip] = useState(false);
	const [isAlert, setAlert] = useState(false);
	const fromLabel = !showForm
		? labels.get('FROM')
		: labels.get('FROM_YOUR_LOCATION');

	const handleInput = e => {
		const newInput = e.target.value;
		setValidZip(validateZip(newInput));

		if (newInput !== input) {
			setInput(newInput);
		}
	};

	const handleAnchorKeyboard = e => {
		if (e.key === 'Enter') {
			setShowForm(true);
		}
	};

	const handleFormSubmit = e => {
		e.preventDefault();

		if (e.key === 'Enter' || e.type === 'click') {
			if (isValidZip) {
				updateLocation(input, e.target);
				setShowForm(false);
				setAlert(false);

				trackEvent(widgetName, windowId, {
					action: 'applied',
					element: 'Zip Code selection',
					elementCTA:
						e.key === 'Enter' ? 'Text input' : 'Text input button',
					result: 'Results updated with facet added'
				});
			} else {
				setAlert(true);
			}
		}
	};

	const form = (
		<form className="w-100 p-0 mt-3">
			<div
				className={setClassNames([
					'd-flex',
					'w-100',
					'p-0',
					'form-control',
					'h-auto',
					isAlert ? 'invalid' : ''
				])}
			>
				<input
					ref={textInput => textInput && textInput.focus()}
					className="form-control form-control-transparent"
					type="search"
					aria-label={labels.get('ENTER_A_NEW_ZIP_CODE')}
					onChange={handleInput}
					onKeyUp={handleFormSubmit}
					placeholder={input}
				/>
				<button
					className={setClassNames([
						'text-link',
						'px-4',
						'text-no-decoration'
					])}
					type="submit"
					onClick={handleFormSubmit}
					disabled={!isValidZip}
					title={labels.get('SEARCH')}
					aria-label={labels.get('SEARCH')}
				>
					<i
						id="skinny-arrow-right"
						className="ddc-icon ddc-icon-arrow1-right"
						aria-hidden="true"
					/>
				</button>
			</div>
		</form>
	);
	return (
		<span
			className={setClassNames([
				'd-block',
				'mt-3',
				'mb-4',
				deviceType === 'DESKTOP' ? 'ddc-font-size-xsmall' : ''
			])}
		>
			<span className="text-muted">{fromLabel}</span>
			{showForm ? (
				form
			) : (
				<button
					className="pl-2 pointer text-link"
					onClick={() => setShowForm(true)}
					onKeyDown={handleAnchorKeyboard}
					type="button"
				>
					{location}
				</button>
			)}
			{isAlert && (
				<span className="d-block mt-1 text-danger">
					{labels.get('AUTOCOMPLETE_HELP_TEXT')}
				</span>
			)}
		</span>
	);
};

LocationInput.propTypes = {
	location: PropTypes.arrayOf(PropTypes.string).isRequired,
	updateLocation: PropTypes.func.isRequired
};

export default LocationInput;
