import React from 'react';
import PropTypes from 'prop-types';
/* eslint react/no-danger: 0 */

const FacetTitle = ({ facetTitle, expandIcon }) => {
	const createMarkup = () => {
		return { __html: `${facetTitle}` };
	};
	return (
		<span className="w-100 d-flex justify-content-between">
			<span
				className="facet-list-group-label text-decoration-none"
				dangerouslySetInnerHTML={createMarkup()}
			/>
			<i
				className={`ddc-icon icon-style-collapse ${expandIcon}`}
				aria-hidden="true"
			/>
		</span>
	);
};

FacetTitle.propTypes = {
	facetTitle: PropTypes.string.isRequired,
	expandIcon: PropTypes.string.isRequired
};

export default FacetTitle;
