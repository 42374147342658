/**
 * Helper used to add 'all' option to radio select facetGroups
 * @param values
 * @param allLabel
 */
export const addAllFacetItem = (values, allLabel) => {
	if (!values.find(item => item.value === 'ALL')) {
		values.unshift({
			label: allLabel,
			value: 'ALL'
		});
	}
	return values;
};
