import { noPrice, priceFacetIds } from '../global-constants';

/**
 * returns lower bucket bound for min
 * @param min
 * @param bucketSize
 * @param facetId
 * @param isMultiselect
 * @returns {number}
 */

export const getLowerBound = (min, bucketSize, facetId, isMultiselect) => {
	let lb = Math.floor(min / bucketSize) * bucketSize;
	if (lb === 0 && priceFacetIds.includes(facetId) && isMultiselect) {
		lb = 1;
	}
	return lb;
};

/**
 * returns higher bucket bound for max
 * @param max
 * @param bucketSize
 * @returns {number}
 */
export const getUpperBound = (max, bucketSize) =>
	Math.ceil(max / bucketSize) * bucketSize;

/**
 * returns closest bucket to number
 * @param num
 * @param bucketSize
 * @returns {number}
 */
export const roundToNearestBucket = (num, bucketSize) =>
	Math.round(num / bucketSize) * bucketSize;

/**
 * Checks to see if a range between min and max contains inventory.
 * This allows us to prevent requests for ranges that would result in no results.
 * @param min
 * @param max
 * @param bucketSize
 * @param bucketMap
 * @param facetId
 * @returns {boolean}
 */
export const rangeContainsInventory = (
	min,
	max,
	bucketSize,
	bucketMap,
	facetId,
	isMultiselect
) => {
	const bottomBucket = getLowerBound(min, bucketSize, facetId, isMultiselect);
	const topBucket = getUpperBound(max, bucketSize);
	for (let b = bottomBucket; b < topBucket; b += bucketSize) {
		if (bucketMap.has(b) && bucketMap.get(b).count) {
			return true;
		}
	}
	if (
		bottomBucket === topBucket &&
		bucketMap.has(bottomBucket) &&
		bucketMap.get(bottomBucket).count
	) {
		return true;
	}
	return false;
};

/**
 * create map of buckets with values from UserRange facet.values.values
 * @param values
 * @returns {Map<number, object>}
 */
export const getBucketMap = (values) => {
	return new Map(values.map((i) => [parseInt(i.value, 10), i]));
};

/**
 * check if noPrice is currently selected
 * @param selectedValues
 * @returns {boolean}
 */
export const isNoPriceActive = (selectedValues) => {
	return !!selectedValues.find((value) => value.value === noPrice);
};

/**
 * This is a temporary fix that will solve cases in which user range facets that should have the
 * 'show no price available' button are not showing that button.
 * The underlying issue is that selecting/unselecting multiselect changes/adds/removes data that
 * we receive from the facet converter. To fix this, updates may need to be made to either the facet converter
 * and/or the composer editor.
 * This is how the 'show no price available' (noPriceObj) should function right now:
 * 		'Show No Price available' selection should only show for 'price' user ranges
 * 			('internetPrice', 'msrp', 'retailValue', 'invoicePrice').
 * 		'Show No Price available' selection should only show when multiselect is selected.
 * @param facet
 * @param isMultiselect
 * @returns {*[]} Array like [facetValues, noPriceObj]
 */
export const transformUserRangeData = (facet, isMultiselect) => {
	const facetValues = facet.values[1] || facet.values[0];
	let noPriceObj = null;

	if (!facetValues.values) {
		return [null, null];
	}
	/**
	 * 'normal' case: where there is 'no price' inventory and multiselect is selected:
	 * The first entry of facet.values is the No Price object
	 */
	if (
		facet.values.length === 2 &&
		priceFacetIds.includes(facet.id) &&
		Object.prototype.hasOwnProperty.call(facet.values[0], 'count')
	) {
		// eslint-disable-next-line prefer-destructuring
		noPriceObj = facet.values[0];
	}
	// When multiselect is off, no priced vehicles will always show and the no price url param will not work, so
	// don't bother showing the 'show no price' option!
	// else if (
	// 	/**
	// 	 * case where there is 'no price' inventory and multiselect is not selected:
	// 	 * The first value of facet.values[1] or facet.values[0] is the No Price object, so pop it out & use it
	// 	 */
	// 	priceFacetIds.includes(facet.id) &&
	// 	facetValues.values.length &&
	// 	facetValues.values[0].value === noPrice
	// ) {
	// 	noPriceObj = facetValues.values.shift();
	// }
	if (!facetValues.values.length) {
		return [null, null];
	}
	// range should start at first bucket containing inventory when 'no price' selection can display
	if (priceFacetIds.includes(facet.id) && facetValues.min === 0) {
		facetValues.min = parseInt(facetValues.values[0].value, 10);
	}
	// When multiselect is selected, the value of the first bucket is '1.0', but it should always be the lowest bound
	if (facetValues.values[0].value === '1.0') {
		facetValues.values[0].value = getLowerBound(
			facetValues.min,
			facetValues.bucketSize,
			facet.id,
			isMultiselect
		).toString();
	}
	return [facetValues, noPriceObj];
};

/**
 * Adjusts values before call to setFacetSelection.
 * 	- No min below bottomLimit, no max above topLimit
 * 	- When min >= max and min is updated, min is set to bucket below max
 * 	- When min >= max and max is updated, max is set to bucket above min
 * @param min
 * @param max
 * @param bottomLimit
 * @param topLimit
 * @param bucketSize
 * @param isMinChange - boolean that describes if min value or max value is being changed.
 * @param facetId
 * @param isMultiSelect
 * @returns {*[]}
 */
export const fixRangeForSubmit = (
	min,
	max,
	bottomLimit,
	topLimit,
	bucketSize,
	isMinChange
) => {
	let bottom = min;
	let top = max;

	if (bottom < bottomLimit) {
		bottom = bottomLimit;
	}
	if (top > topLimit) {
		top = topLimit;
	}
	if (bottom >= top && isMinChange) {
		bottom = Math.max(
			bucketSize !== 1 ? top - bucketSize : top,
			bottomLimit
		);
	} else if (bottom >= top && !isMinChange) {
		top = Math.min(
			bucketSize !== 1 ? bottom + bucketSize : bottom,
			topLimit
		);
	}

	return [bottom, top];
};

/**
 * Convert input string to snake case format
 * @param {*} inputString Like 'bodyStyle','compositeType'
 * @returns {string} string like 'body_style, 'composite_type'
 */
export const convertCameltoSnakeCase = (inputString) => {
	return (inputString || '').replace(
		/[A-Z]/g,
		(letter) => `_${letter.toLowerCase()}`
	);
};
