import React from 'react';
import { useRequestData, usePrefs } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { formatNumber } from 'ddc-intl';

const FacetCount = ({ count }) => {
	const { locale } = useRequestData();
	const { showCountParenthesis } = usePrefs();

	const formattedCount = formatNumber(count, locale);
	const countValue =
		showCountParenthesis === 'true'
			? `(${formattedCount})`
			: formattedCount;

	return <small className="ddc-font-size-xsmall">&#xFEFF;{countValue}</small>;
};

FacetCount.propTypes = {
	count: PropTypes.number.isRequired
};

export default React.memo(FacetCount);
