/**
 * This function uses a regex expression to validate both US and CA zip codes
 * @param val
 * @returns {boolean}
 */
/* eslint one-var: ["error", "always"] */
export const validateZip = val => {
	const postalRegEx = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/g,
		isPostalCode = new RegExp(postalRegEx);

	return isPostalCode.test(val);
};
