import React from 'react';
import Skeleton from 'wsm-skeleton';

const FacetSkeleton = () => {
	return (
		<Skeleton
			skeletonClass="facet-list-group panel panel-default"
			aspectRatio="4by3"
		/>
	);
};

export default FacetSkeleton;
