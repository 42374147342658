/**
 * Compare two array have items equally in values, order not matter, primitive value only,
 *
 * @param {array} arr1 original array
 * @param {array} arr2 compare array
 * @returns {boolean}
 */
export const isArrayEqual = (arr1, arr2) => {
	const difference = arr1?.filter((x) => !arr2?.includes(x));
	return difference?.length === 0;
};
