import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocationItem from './LocationItem';
/* eslint react/no-danger: 0 */

const LocationList = ({ data }) => {
	const accountIdFilter = window?.DDC?.InvData?.getAppliedFilters().find(
		(filter) => filter.id === 'accountId'
	);
	const storeValues = accountIdFilter ? accountIdFilter.values : [];
	const [storeList, setStoreList] = useState(storeValues);

	const isRadioInput = data[0].value === 'ALL';

	useEffect(() => {
		// Checkbox type: storeList.length !== storeValues.length: the storeValues array will change the length when user updates the Shop by store
		// Radio type: use storeList[0]?.value !== storeValues[0]?.value, the storeValues array will change the value at the first index when
		// user updates the Shop by store (always 1)
		if (
			storeList.length !== storeValues.length ||
			storeList[0]?.value !== storeValues[0]?.value
		) {
			setStoreList(storeValues);
		}
	}, [storeValues]);
	return (
		<ul className="list-unstyled line-height-condensed">
			{data &&
				data.map((item, index) => (
					<LocationItem
						data={item}
						index={index}
						storeList={storeList}
						isRadioInput={isRadioInput}
						key={`label-location-item-${item.value}`}
					/>
				))}
		</ul>
	);
};

LocationList.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			count: PropTypes.number,
			label: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	)
};

export default LocationList;
