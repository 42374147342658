import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const GeoRange = ({ range, updateRange, geoRangeLabel }) => {
	const { geoRadiusValues } = window.DDC.InvData.getGeoDataPrefs() || {};
	const labels = useLabels();

	if (!geoRadiusValues) {
		return <></>;
	}

	const getRadiusValues = () => {
		const optionsArr = [];

		geoRadiusValues.split(',').forEach((value) => {
			const isAnyDistance = value === '0';

			optionsArr.push({
				value,
				label: isAnyDistance
					? `${labels.get('ANY_DISTANCE')}`
					: `${value} ${labels.get('MILES')}`
			});
		});

		return optionsArr;
	};

	const distanceShippingOption = geoRangeLabel
		? { id: 'distance-shipping-label-zip' }
		: {};

	const formControl = (
		<select
			className="form-control font-size-ios-zoom-override"
			aria-label={labels.get('ZIP_CODE_RANGE_DROPDOWN')}
			onChange={updateRange}
			value={range}
			{...distanceShippingOption}
		>
			{getRadiusValues(geoRadiusValues).map(({ value, label }) => (
				<option key={value} value={value}>
					{label}
				</option>
			))}
		</select>
	);

	return (
		<>
			{geoRangeLabel ? (
				<label
					className="ddc-font-size-xsmall"
					htmlFor="distance-shipping-label-zip"
				>
					<span>Shop by zip</span>
					<div className="mt-3">{formControl}</div>
				</label>
			) : (
				formControl
			)}
		</>
	);
};

GeoRange.propTypes = {
	range: PropTypes.string.isRequired,
	updateRange: PropTypes.func.isRequired,
	geoRangeLabel: PropTypes.string
};

export default GeoRange;
