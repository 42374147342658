/**
 * Creates a new array inserting a single item into position at index.
 *
 * @param {array} arr original array
 * @param {number} index position to insert
 * @param {any} item item to insert
 * @returns {array}
 */
export const arrayInsertSingle = (arr, index, item) => [
	...arr.slice(0, index),
	item,
	...arr.slice(index)
];
