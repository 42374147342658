/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import FacetCount from './FacetCount';
import ColorSwatch from './ColorSwatch';

const Facet = ({
	facetLabel,
	facetType,
	facetValue,
	facetCount,
	groupId,
	isSelected,
	isDisabled,
	onChange
}) => {
	const { showFacetCounts } = usePrefs();
	const getSwatchColor = (group, value) => {
		if (value === 'ALL') return null;
		if (
			group === 'normalExteriorColor' ||
			(group === 'normalInteriorColor' && value)
		) {
			return value;
		}
		return null;
	};
	const swatchColor = getSwatchColor(groupId, facetValue);

	return (
		<li className={`${facetType} mb-lg-4`} key={`${groupId}-${facetValue}`}>
			<label
				className={isDisabled ? 'disabled' : null}
				htmlFor={`${groupId}-${facetValue}`}
			>
				<input
					type={facetType}
					name={groupId}
					value={facetValue}
					id={`${groupId}-${facetValue}`}
					onChange={(e) => onChange(e, groupId)}
					checked={isSelected}
					disabled={isDisabled}
				/>
				<span className="facet-list-facet-label-text-container">
					{swatchColor && <ColorSwatch color={swatchColor} />}
					<span className="facet-list-facet-label-text">
						<span
							dangerouslySetInnerHTML={{ __html: facetLabel }}
						/>
						{showFacetCounts === 'true' && facetCount && (
							<FacetCount count={facetCount} />
						)}
					</span>
				</span>
			</label>
		</li>
	);
};

Facet.propTypes = {
	facetType: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	isSelected: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	facetLabel: PropTypes.string.isRequired,
	facetValue: PropTypes.string.isRequired,
	groupId: PropTypes.string.isRequired,
	facetCount: PropTypes.number
};

Facet.defaultProps = {
	facetCount: undefined
};

export default Facet;
