import React from 'react';
import PropTypes from 'prop-types';
/* eslint react/no-danger: 0 */

const LocationItem = ({ data, index, storeList, isRadioInput }) => {
	const inputType = isRadioInput ? 'radio' : 'checkbox';
	const findStoreIndex = storeList.findIndex(
		(item) => item.value === data.value
	);
	const isSelectAllLocation = data.value === 'ALL' && storeList.length === 0;
	const isChecked = findStoreIndex !== -1 || isSelectAllLocation;

	const updateSelectedStores = () => {
		const selectedStores = storeList.map((selected) => selected.value);
		const storeIndex = selectedStores.indexOf(data.value);

		if (storeIndex !== -1) {
			selectedStores.splice(storeIndex, 1);
		} else {
			selectedStores.push(data.value);
		}

		return selectedStores;
	};

	const onStoreSelect = (e) => {
		let selectedStores = [];

		if (isRadioInput) {
			// For radio inputs, set selectedStores to the selected value if it's not 'ALL'
			if (data.value !== 'ALL') {
				selectedStores = [data.value];
			}
		} else {
			// For checkbox inputs, generate the updated store list
			selectedStores = updateSelectedStores();
		}

		window.DDC.InvData.setFilterSelections({
			filterName: 'accountId',
			filterValue: data.value,
			filterValues: selectedStores,
			filterTitleText: 'Distance Shipping',
			isSelected: selectedStores.includes(data.value),
			element: e?.target
		});
	};

	return (
		<li className="checkbox mb-lg-4 mb-3">
			<label
				htmlFor={`label-location-item-${data.value}-${index}`}
				className="pl-0"
				id={`label-location-item-${data.value}`}
			>
				<input
					type={inputType}
					value={data.value}
					id={`label-location-item-${data.value}-${index}`}
					className="ml-0"
					onChange={onStoreSelect}
					checked={isChecked}
				/>
				<span className="facet-list-facet-label-text-container">
					<span className="facet-list-facet-label-text">
						<span
							className={`ml-0 ${
								inputType === 'checkbox' ? 'ml-lg-4' : 'ml-lg-2'
							} pl-2 ddc-font-size-small`}
						>
							{data.label}
							<small>{data.count}</small>
						</span>
					</span>
				</span>
			</label>
		</li>
	);
};

LocationItem.propTypes = {
	data: PropTypes.shape({
		count: PropTypes.number,
		label: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired
	}),
	index: PropTypes.number.isRequired,
	storeList: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired
		})
	).isRequired,
	isRadioInput: PropTypes.bool.isRequired
};

export default LocationItem;
