import PropTypes from 'prop-types';

const WebIntegrationPlaceholder = ({ location, locationId }) => {
	return <div data-location={location} data-location-id={locationId} />;
};

WebIntegrationPlaceholder.propTypes = {
	location: PropTypes.string.isRequired,
	locationId: PropTypes.string
};

WebIntegrationPlaceholder.defaultProps = {
	locationId: null
};

export default WebIntegrationPlaceholder;
