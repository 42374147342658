import PropTypes from 'prop-types';
import { usePrefs, useSitemap } from 'wsm-common-data';
import logger from 'ws-scripts/modules/logger';
import { EvModal, CustomMarkupModal, useFetchFragment } from 'wsm-common-modal';

const Modals = ({ activeFacetModal, options }) => {
	const sitemap = useSitemap();
	const { showCompositeTypeLink } = usePrefs();

	const compositeTypeHref = sitemap.get(showCompositeTypeLink);

	const { data, isLoading, isError } = useFetchFragment(
		logger,
		compositeTypeHref
	);

	return (
		<>
			{activeFacetModal === 'compositeType' ? (
				<CustomMarkupModal
					{...options}
					content={data}
					isLoading={isLoading}
					isError={isError}
				/>
			) : (
				<EvModal {...options} />
			)}
		</>
	);
};

Modals.propTypes = {
	activeFacetModal: PropTypes.string,
	options: PropTypes.shape({})
};

export default Modals;
