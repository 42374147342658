import React from 'react';
import PropTypes from 'prop-types';

const ColorSwatch = ({ color }) => {
	return (
		<span
			className={`normalized-swatch normalized-swatch-${color.toLowerCase()} mr-3`}
		/>
	);
};

ColorSwatch.propTypes = {
	color: PropTypes.string
};

ColorSwatch.defaultProps = {
	color: null
};

export default React.memo(ColorSwatch);
